import { isPast } from "date-fns";
import AppointmentStatus from "@olivahealth/graphql-server/src/domain/value-objects/AppointmentStatus";

export function getDataProcessingConsent({
  employeeSettings,
  sessionEndDate,
  sessionStatus,
}: {
  employeeSettings?: {
    hasGivenSessionSummarisationConsent?: boolean | null;
  } | null;
  sessionEndDate: string;
  sessionStatus: AppointmentStatus;
}): boolean | null | undefined {
  if (
    isPast(new Date(sessionEndDate)) &&
    sessionStatus !== AppointmentStatus.ACTIVE
  ) {
    return Boolean(employeeSettings?.hasGivenSessionSummarisationConsent);
  }

  return employeeSettings?.hasGivenSessionSummarisationConsent;
}
