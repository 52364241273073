import cx from "classnames";
import { graphql, useFragment } from "react-relay";
import { Text } from "@olivahealth/oli-ui";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Navigation } from "swiper/modules";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import SessionCard from "../../organisms/SessionCard";
import { UpcomingEventsFragment_data$key as IFragment } from "./__generated__/UpcomingEventsFragment_data.graphql";
import { upcomingEvents as s } from "./styles";
import "swiper/css";

interface Props {
  addSpaceAfter: boolean;
  data: IFragment;
}

const UpcomingEventsFragment = graphql`
  fragment UpcomingEventsFragment_data on Query
  @argumentDefinitions(userId: { type: "String" }) {
    sessions(
      filterBy: { userId: $userId }
      only: UPCOMING
      include: { groupSessions: true, cancelled: false }
      sortBy: { scheduledDate: ASC }
      first: 2
    ) {
      nodes {
        __typename
        ... on GroupSession {
          id
        }
        ... on Session {
          id
        }
        ...useSessionCard_session
      }
    }
  }
`;

export default function UpcomingEvents({
  addSpaceAfter,
  data,
}: Props): JSX.Element {
  const { t } = useTranslation("employeeDashboard", {
    keyPrefix: "upcomingEvents",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const fragmentData = useFragment<IFragment>(UpcomingEventsFragment, data);
  const sessions =
    fragmentData?.sessions.nodes.filter(
      (session) => Object.keys(session).length !== 0,
    ) ?? [];

  const hasEmptyState = !sessions.length;

  return (
    <>
      <Text variant="h3" gutter="xl">
        {t("title")}
      </Text>
      <div
        className={cx("flex flex-col", {
          "md:mb-14": addSpaceAfter,
        })}
        data-testid="upcoming-events"
      >
        {hasEmptyState && <EmptyState />}
        {!hasEmptyState && isTabletOrMobile && (
          <div>
            <Swiper
              grabCursor
              modules={[A11y, Mousewheel, Navigation]}
              mousewheel={{
                releaseOnEdges: true,
              }}
              spaceBetween={16}
              slidesPerView={sessions.length === 1 ? 1 : 1.5}
              threshold={20}
              className="!p-1"
            >
              {sessions.map((session) => (
                <SwiperSlide key={session.id}>
                  <SessionCard session={session} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        {!hasEmptyState && !isTabletOrMobile && (
          <div className="grid grid-cols-2 gap-6">
            {sessions.map((session) => (
              <SessionCard key={session.id} session={session} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

function EmptyState() {
  const { t } = useTranslation("employeeDashboard", {
    keyPrefix: "upcomingEvents",
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <div className={s.emptyStateWrapper}>
      <Image
        src={"/img/illustrations/general/managementAdd.png"}
        alt="empty state icon"
        height={96}
        width={96}
        className="mb-4 md:mb-0"
      />

      <div className="flex flex-col md:ml-5">
        <Text gutter="sm" size={isTabletOrMobile ? "3xl" : "2xl"} weight="bold">
          {t("emptyStateTitle")}
        </Text>
        <Text color="primary-light">{t("emptyStateSubtitle")}</Text>
      </div>
    </div>
  );
}
