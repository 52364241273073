import { useState } from "react";
import { useRouter } from "next/router";
import { MessageBanner } from "@olivahealth/oli-ui";

import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import { useSharedStoreContext } from "../../../services/contexts/SharedStoreContext";

export default function NewMatchesBanner() {
  const { push } = useRouter();
  const {
    employeeDashboard: { hasSeenNewMatchesBanner },
    setSharedStore,
  } = useSharedStoreContext();
  const { t } = useTranslation("employeeDashboard", {
    keyPrefix: "newMatchesBanner",
  });

  const [isOpen, setIsOpen] = useState(!hasSeenNewMatchesBanner);

  if (!isOpen) {
    return null;
  }

  const handleCloseBanner = () => {
    setIsOpen(false);
    setSharedStore({ employeeDashboard: { hasSeenNewMatchesBanner: true } });
  };

  return (
    <div className="my-16">
      <MessageBanner
        primaryAction={{
          action: () => {
            push("/support");
            handleCloseBanner();
          },
          title: t("ctaTitle"),
        }}
        icon={
          <Image
            src="/img/new_matches.png"
            width={96}
            height={96}
            alt={t("iconAlt")}
          />
        }
        isOpen={isOpen}
        onCloseHook={handleCloseBanner}
        message={t("message")}
        variant="secondary"
      />
    </div>
  );
}
