import {
  ArrowIcon,
  BackgroundHeader,
  Button,
  Divider,
  ExclamationIcon,
  FeaturedBanner,
  Text,
} from "@olivahealth/oli-ui";
import { useRouter } from "next/router";
import MentalHealthTopics from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthTopics";
import { useState } from "react";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import { useUserData } from "../../../services/contexts/UserDataContext";
import { ErrorBoundary } from "../../../components/common/ErrorBoundary";
import PageLayout from "../../templates/Page";
import TopicBadge from "../../../components/common/TopicBadge";
import ErrorPage from "../Error";
import AddPersonalEmailBanner from "../../molecules/AddPersonalEmailBanner";
import PendingSurveyBanner from "../../molecules/PendingSurveyBanner";
import PendingSurveyEmployeeUnsatisfiedWithProfessionalBanner from "../../molecules/PendingSurveyEmployeeUnsatisfiedWithProfessionalBanner";
import BookDropInSessionCard from "../../organisms/BookDropInSessionCard";
import WellbeingProfileScoreDisplay from "../../organisms/WellbeingProfileScoreDisplay";
import useEmployeeDashboard from "./useEmployeeDashboard";
import UpcomingEvents from "./UpcomingEvents";
import SelfGuided from "./SelfGuided";
import WellbeingProfileCard from "./WellbeingProfileCard";
import FindingMatchCard from "./FindingMatchCard";
import BookingConfirmationDialog from "./BookingConfirmationDialog";
import NewMatchesBanner from "./NewMatchesBanner";
import GetStartedBanner from "./GetStartedBanner";
import EmployeeNPSSurvey from "./EmployeeNPSSurvey";

export default function EmployeeDashboard(): JSX.Element {
  const { t } = useTranslation("employeeDashboard");
  const { t: tWellbeing } = useTranslation("wellbeingProfile");
  const { push, query, replace } = useRouter();
  const {
    status,
    effects: { navigateToSelfGuided, setShowBookingConfirmationDialog },
    data: {
      queryData,
      sessionIdWithPendingCheckIn,
      showGetStartedBanner,
      showStartTriagingCard,
      latestFocusArea,
      showWellbeingProfileV1,
      showRefreshProfileButton,
      startCareCard,
      showFindingMatchCard,
      wellbeingProfileResults,
      scheduleFirstSessionCard,
      showBookOneToOneCard,
      showPersonalEmailBanner,
      showBookingConfirmationDialog,
      showNewMatchesBanner,
      showPendingSurveyEmployeeUnsatisfiedWithProfessionalBanner,
    },
  } = useEmployeeDashboard({ sessionId: query?.success as string });
  const { data: user } = useUserData();

  const topic = queryData.wellbeingProfile.topic;

  const [showStartCareCard, setShowStartCareCard] = useState(false);

  if (status === "error") {
    return (
      <ErrorPage
        title={t("errorPage.title")}
        description={t("errorPage.description")}
      />
    );
  }

  return (
    <div>
      {showGetStartedBanner ? (
        <GetStartedBanner hasWellbeingProfile={showWellbeingProfileV1} />
      ) : (
        <BackgroundHeader
          enableParallax={false}
          showBackLink={false}
          imagePath="/img/img_background_self_guided_area.png"
          imageAlt="background image"
          title={
            user?.name
              ? t("welcomeTitle", { name: user.name })
              : t("welcomeTitleNoName")
          }
        >
          <EmployeeNPSSurvey />
          {wellbeingProfileResults?.length > 0 && (
            <WellbeingProfileScoreDisplay
              onClose={() => {
                setShowStartCareCard(true);
              }}
              wellbeingProfileResults={wellbeingProfileResults}
              showStartCareCard={startCareCard.show}
              showUpdateButton={showRefreshProfileButton}
              focusArea={latestFocusArea}
            />
          )}
        </BackgroundHeader>
      )}
      <PageLayout>
        <div data-testid="employee-dashboard">
          <div className="flex max-w-screen-xl mx-auto flex-col">
            {showPendingSurveyEmployeeUnsatisfiedWithProfessionalBanner && (
              <PendingSurveyEmployeeUnsatisfiedWithProfessionalBanner />
            )}

            {showPersonalEmailBanner && (
              <div className="mb-16">
                <AddPersonalEmailBanner
                  organisationName={user?.organisation?.name ?? ""}
                />
              </div>
            )}

            {sessionIdWithPendingCheckIn && (
              <div className="mb-16">
                <PendingSurveyBanner
                  surveyUrl={`/sessions/${sessionIdWithPendingCheckIn}/talk`}
                />
              </div>
            )}

            <div className="flex flex-col mb-5">
              {user?.hasBookedSessions && (
                <UpcomingEvents
                  data={queryData}
                  addSpaceAfter={
                    showStartTriagingCard || showWellbeingProfileV1
                  }
                />
              )}

              {showStartTriagingCard && !showGetStartedBanner && (
                <div
                  data-testid="start-triaging-card"
                  data-intercom-target="start-triaging-card-intercom"
                >
                  <FeaturedBanner
                    buttonLabel={t("startTriagingCard.buttonText")}
                    buttonOnClick={() => push("/wellbeing")}
                    content={t("startTriagingCard.subtitle")}
                    title={t("startTriagingCard.title")}
                    tagLabel={t("startTriagingCard.heading")}
                    image={
                      <Image
                        src="/img/img_wellbeing_shape_example_dark.png"
                        alt={t("startTriagingCard.imageAltText")}
                        width={304}
                        height={252}
                      />
                    }
                    info={
                      <span className="flex items-center">
                        <ExclamationIcon size={16} className="mr-2" />
                        <span>{t("startTriagingCard.estimationText")}</span>
                      </span>
                    }
                  />
                </div>
              )}

              {showNewMatchesBanner && <NewMatchesBanner />}
              {showWellbeingProfileV1 && (
                <>
                  <WellbeingProfileCard
                    data={queryData.wellbeingProfile}
                    showRefreshButton={showRefreshProfileButton}
                  />
                  <Divider margin="xl" />
                </>
              )}
            </div>

            <div className="flex flex-col gap-8">
              {showWellbeingProfileV1 && (
                <div className="flex items-center gap-2">
                  <Text variant="h3">
                    {user?.name &&
                      topic &&
                      t("supportTitleWithWellbeingProfile", {
                        name: user?.name,
                      })}
                  </Text>
                  <TopicBadge
                    key="supportTitleTopic"
                    topic={topic ?? MentalHealthTopics.OVERALL_WELLNESS}
                    size="lg"
                  />
                </div>
              )}

              {showStartCareCard && (
                <div className="flex flex-col mt-4">
                  <Text variant="h3" gutter="lg">
                    {t("startCareTitle", {
                      focusArea: tWellbeing(`focusAreas.${latestFocusArea}`),
                    })}
                  </Text>
                  <FeaturedBanner
                    buttonLabel={t("startCareCard.buttonText")}
                    buttonOnClick={() => {
                      push("/triaging/start-care");
                    }}
                    content={startCareCard.cardContent}
                    title={t("startCareCard.title")}
                    tagLabel={t("startCareCard.heading")}
                    image={
                      <Image
                        src="/img/img_planet.png"
                        alt={t("startCareCard.imageAltText")}
                        width={304}
                        height={252}
                      />
                    }
                    info={
                      !showWellbeingProfileV1 && (
                        <span className="flex items-center">
                          <ExclamationIcon size={16} className="mr-2" />
                          <span>
                            {t("startCareCard.completeProfileDisclaimer")}
                          </span>
                        </span>
                      )
                    }
                  />
                </div>
              )}

              {showFindingMatchCard && <FindingMatchCard />}

              {scheduleFirstSessionCard.therapyId && (
                <FeaturedBanner
                  buttonLabel={t("scheduleFirstSessionCard.buttonText")}
                  buttonOnClick={() =>
                    push(
                      `/support?schedule=THERAPY&therapyId=${scheduleFirstSessionCard.therapyId}`,
                    )
                  }
                  content={scheduleFirstSessionCard.cardContent}
                  title={t("scheduleFirstSessionCard.title")}
                  tagLabel={t("scheduleFirstSessionCard.heading")}
                  image={
                    <Image
                      src="/img/img_planet.png"
                      alt={t("scheduleFirstSessionCard.imageAltText")}
                      width={304}
                      height={252}
                    />
                  }
                />
              )}

              {showBookOneToOneCard && <BookDropInSessionCard />}

              {(showWellbeingProfileV1 || showBookOneToOneCard) && (
                <div className="mb-10" />
              )}
            </div>

            <section data-testid="self-guided">
              <div className="mb-6 mt-16 flex justify-between">
                <div className="flex flex-col">
                  <Text variant="h3" gutter="sm">
                    {t("selfGuidedTitle")}
                  </Text>
                  <Text color="secondary">{t("selfGuidedSubtitle")}</Text>
                </div>
                <div className="hidden md:block">
                  <Button
                    variant="tertiary"
                    iconRight={<ArrowIcon direction="right" />}
                    onClick={navigateToSelfGuided}
                  >
                    {t("selfGuidedCta")}
                  </Button>
                </div>
              </div>
              <SelfGuided items={queryData} />
              <div className="ml-auto mt-4 md:hidden">
                <Button
                  variant="tertiary"
                  iconRight={<ArrowIcon direction="right" />}
                  onClick={navigateToSelfGuided}
                >
                  {t("selfGuidedCta")}
                </Button>
              </div>
            </section>
          </div>

          {showBookingConfirmationDialog && queryData.session && (
            <ErrorBoundary>
              <BookingConfirmationDialog
                isOpen={showBookingConfirmationDialog}
                onClose={() => {
                  setShowBookingConfirmationDialog(false);
                  replace("/", undefined, { shallow: true });
                }}
                onButtonClick={() => {
                  push(`/sessions/${query?.success as string}`);
                }}
                data={queryData.session}
              />
            </ErrorBoundary>
          )}
        </div>
      </PageLayout>
    </div>
  );
}
