/**
 * @generated SignedSource<<c1bdbc17e49f838de3b191481d91fe10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SessionStatusEnum = "ACTIVE" | "AWAITING_PAYMENT" | "CANCELLED" | "CANCELLED_LATE" | "COMPLETED" | "EMPLOYEE_NO_SHOW" | "NO_SHOW" | "PAYMENT_EXPIRED" | "PROFESSIONAL_NO_SHOW" | "SCHEDULED";
export type useProfessionalDashboardQuery$variables = {
  includePast: boolean;
  userId: string;
};
export type useProfessionalDashboardQuery$data = {
  readonly past?: {
    readonly nodes: ReadonlyArray<{
      readonly __typename: string;
      readonly id?: string;
      readonly status?: SessionStatusEnum;
      readonly " $fragmentSpreads": FragmentRefs<"useProfessionalSessionCard_fragment">;
    }>;
  };
  readonly upcoming: {
    readonly nodes: ReadonlyArray<{
      readonly __typename: string;
      readonly id?: string;
      readonly startDate?: string;
      readonly status?: SessionStatusEnum;
      readonly " $fragmentSpreads": FragmentRefs<"useProfessionalSessionCard_fragment">;
    }>;
  };
};
export type useProfessionalDashboardQuery = {
  response: useProfessionalDashboardQuery$data;
  variables: useProfessionalDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includePast"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "userId",
      "variableName": "userId"
    }
  ],
  "kind": "ObjectValue",
  "name": "filterBy"
},
v3 = {
  "kind": "Literal",
  "name": "include",
  "value": {
    "cancelled": true
  }
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "only",
    "value": "UPCOMING"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": {
      "scheduledDate": "ASC"
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "useProfessionalSessionCard_fragment"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v10 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "kind": "InlineFragment",
  "selections": (v10/*: any*/),
  "type": "Session",
  "abstractKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": (v10/*: any*/),
  "type": "GroupSession",
  "abstractKey": null
},
v13 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "only",
    "value": "PAST"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": {
      "scheduledDate": "DESC"
    }
  }
],
v14 = [
  (v7/*: any*/),
  (v8/*: any*/)
],
v15 = {
  "kind": "InlineFragment",
  "selections": (v14/*: any*/),
  "type": "Session",
  "abstractKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": (v14/*: any*/),
  "type": "GroupSession",
  "abstractKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancelledByName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancelledById",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v21 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkoutUrl",
          "storageKey": null
        },
        (v8/*: any*/),
        (v17/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "employee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "surname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserSettings",
              "kind": "LinkedField",
              "name": "settings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasGivenSessionSummarisationConsent",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SessionAppointment",
          "kind": "LinkedField",
          "name": "appointment",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rescheduledPreviousStartDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v18/*: any*/),
        (v19/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomatedSessionSummary",
          "kind": "LinkedField",
          "name": "automatedSummary",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "summary",
              "plural": false,
              "selections": [
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v20/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transcriptionStatus",
          "storageKey": null
        }
      ],
      "type": "Session",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v7/*: any*/),
        (v8/*: any*/),
        (v17/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Workshop",
          "kind": "LinkedField",
          "name": "workshop",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "illustrationCard",
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "participants",
          "plural": true,
          "selections": [
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        (v18/*: any*/),
        (v19/*: any*/),
        (v20/*: any*/)
      ],
      "type": "GroupSession",
      "abstractKey": null
    }
  ],
  "type": "SessionResponse",
  "abstractKey": "__isSessionResponse"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useProfessionalDashboardQuery",
    "selections": [
      {
        "alias": "upcoming",
        "args": (v4/*: any*/),
        "concreteType": "SessionsResponseConnection",
        "kind": "LinkedField",
        "name": "sessions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "includePast",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "past",
            "args": (v13/*: any*/),
            "concreteType": "SessionsResponseConnection",
            "kind": "LinkedField",
            "name": "sessions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useProfessionalDashboardQuery",
    "selections": [
      {
        "alias": "upcoming",
        "args": (v4/*: any*/),
        "concreteType": "SessionsResponseConnection",
        "kind": "LinkedField",
        "name": "sessions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v21/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "includePast",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "past",
            "args": (v13/*: any*/),
            "concreteType": "SessionsResponseConnection",
            "kind": "LinkedField",
            "name": "sessions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v21/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "d80c6bdf6794df712d1d1cab7e0ffaac",
    "id": null,
    "metadata": {},
    "name": "useProfessionalDashboardQuery",
    "operationKind": "query",
    "text": "query useProfessionalDashboardQuery(\n  $userId: String!\n  $includePast: Boolean!\n) {\n  upcoming: sessions(filterBy: {userId: $userId}, include: {cancelled: true}, only: UPCOMING, sortBy: {scheduledDate: ASC}) {\n    nodes {\n      __typename\n      ...useProfessionalSessionCard_fragment\n      ... on Session {\n        id\n        status\n        startDate\n      }\n      ... on GroupSession {\n        id\n        status\n        startDate\n      }\n    }\n  }\n  past: sessions(filterBy: {userId: $userId}, include: {cancelled: true}, only: PAST, sortBy: {scheduledDate: DESC}) @include(if: $includePast) {\n    nodes {\n      __typename\n      ...useProfessionalSessionCard_fragment\n      ... on Session {\n        id\n        status\n      }\n      ... on GroupSession {\n        id\n        status\n      }\n    }\n  }\n}\n\nfragment useProfessionalSessionCard_fragment on SessionResponse {\n  __isSessionResponse: __typename\n  __typename\n  ... on Session {\n    id\n    type\n    checkoutUrl\n    status\n    endDate\n    startDate\n    employee {\n      name\n      surname\n      settings {\n        hasGivenSessionSummarisationConsent\n      }\n      id\n    }\n    appointment {\n      rescheduledPreviousStartDate\n    }\n    cancelledByName\n    cancelledById\n    automatedSummary {\n      summary {\n        __typename\n        ... on ProcessedProfessionalSessionSummary {\n          __typename\n        }\n      }\n    }\n    language\n    transcriptionStatus\n  }\n  ... on GroupSession {\n    id\n    status\n    endDate\n    startDate\n    workshop {\n      title\n      illustrationCard\n      id\n    }\n    participants {\n      id\n    }\n    cancelledByName\n    cancelledById\n    language\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a74b82645de61903084f8c3957e565d";

export default node;
