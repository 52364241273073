enum WellbeingProfileFocusAreas {
  EMOTIONAL = "EMOTIONAL",
  FINANCIAL = "FINANCIAL",
  SLEEP = "SLEEP",
  PROFESSIONAL = "PROFESSIONAL",
  PHYSICAL = "PHYSICAL",
}

export const WellbeingProfileFocusAreasValues = [
  WellbeingProfileFocusAreas.EMOTIONAL,
  WellbeingProfileFocusAreas.FINANCIAL,
  WellbeingProfileFocusAreas.SLEEP,
  WellbeingProfileFocusAreas.PROFESSIONAL,
  WellbeingProfileFocusAreas.PHYSICAL,
];

export default WellbeingProfileFocusAreas;
